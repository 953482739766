.footer{
 padding-bottom: 10px;
  .copyright{
    a{
      font-weight: 600;
    }
  }
}


.lh-35{
  line-height: 35px;
}


.logo{
  color: $black;
  font-weight: 600;
  letter-spacing: 1px;

  span{
    color: $primary-color;
  }
}

.logo h3::before{
	content:url("../images/logo_xs_quadrat.png");
	padding-right:12px;
	float:left;
}
			
.sub-form{
  position: relative;
  .form-control{
    border: 1px solid rgba(0,0,0,0.06);
    background: $secondary-color;
  }

}

.footer-btm{
  border-top: 1px solid rgba(0,0,0,0.4);

}

.footer-socials{
  li a{
    margin-left: 15px;
  }
}