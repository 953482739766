.contact-form-wrap{
      .form-group{
        padding-bottom: 15px;
        margin: 0px;
        .form-control{
          height: 48px;
          border: 1px solid $secondary-color;
          box-shadow: none;
          width: 100%;
        }
      }
      .form-group-2{
        margin-bottom: 13px;
        textarea{
          height: 135px;
          border: 1px solid $secondary-color;
          box-shadow: none;
          width: 100%;
        }
      }
}


.address-block {
  li {
    margin-bottom:10px;
    i {
      font-size: 20px;
      width: 20px;
    }
  }
}

.loading {
  position: relative;
}
.loading:after {
  position: absolute;
  top: 0%;
  left: 0%;
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8) url('../images/loader-large.gif') no-repeat 50% 50%;
  visibility: visible;
  z-index:5;
}

.social-icons {
  li {
    margin:0 6px;
  }
 
  i{
    margin-right: 15px;
    font-size: 25px;
  }
}

.google-map {
    position: relative;
}

.google-map #map {
    width: 100%;
    height: 450px;
}

