// Testimonial Section

.logo-carousel {
  overflow: inherit;
  margin:30px 20px;
  display:none;
}
.logo-carousel.slick-initialized {
  display:block;
}
.slick-prev:before,
.slick-next:before {
  color: $primary-color;
}
.slick-slide {
  margin: 0px 20px;  
  &:focus, a {
    outline: none;
  }
}