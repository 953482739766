$light: #fff;
// $primary-color: rgb(247, 87, 87);
$primary-color: #43c2ed;
$secondary-color: #052860;
$event-color: #c54041;
$law-color: #b99769;
$black: #222;
$border-color:#dedede;
$primary-font:'Open Sans', sans-serif;
$secondary-font:'Poppins', sans-serif;
$extra-font:'Hind', serif;
