
/*=== MEDIA QUERY ===*/

@include mobile-xs{
   .header-top .header-top-info a {
      margin-left: 0px;
      display: block;
  }
  .navbar-brand img {
    width:100px;
    top: -10px;
    -webkit-transition:all .3s ease-out 0s;
    -moz-transition:all .3s ease-out 0s;
    -ms-transition:all .3s ease-out 0s;
    -o-transition:all .3s ease-out 0s;
    transition:all .3s ease-out 0s   
  }
  .navbar-toggler{
    color: $light;
  }
  .slider .block h1 {
      font-size: 28px;
      line-height: 40px;
  }

  .slider{
    padding: 135px 0 151px 0;
  }

  .content-title {
      font-size: 28px;
      line-height: 46px;
  }

  .bg-about{
    display: none;
  }
  
  .counter{
    background: $primary-color;
  }
  
  .p-5{
    padding: 2rem !important;
  }
  h2, .h2 {
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 36px;
  }

  .testimonial-item .testimonial-item-content {
      padding-left: 0px;
      padding-top: 30px;
  }

  .footer-socials {
    margin-top: 20px;
  }
  .footer-socials li a {
      margin-left: 0px;
  }

  .text-lg {
      font-size: 3rem;
  }

  .header-top .header-top-info {
      padding-bottom: 15px;
  }
  .blog-item-meta span{
    margin: 6px 0px;
  }

  .widget {
      margin-bottom: 30px;
      padding-bottom: 0px; 
  }
  
  .dropdown-menu{
    display: none;
    width: 100%;
    text-align: center;
  }
}

@include mobile{
  .header-top .header-top-info a {
      margin-left: 0px;
  }
  
  .navbar-brand img {
    width:100px;
    top: -10px;
    -webkit-transition:all .3s ease-out 0s;
    -moz-transition:all .3s ease-out 0s;
    -ms-transition:all .3s ease-out 0s;
    -o-transition:all .3s ease-out 0s;
    transition:all .3s ease-out 0s   
  }
  .navbar-toggler{
    color: $light;
  }
  .slider .block h1 {
      font-size: 38px;
      line-height: 50px;
  }

  .slider{
    padding: 135px 0 151px 0;
  }

  .content-title {
      font-size: 28px;
      line-height: 46px;
  }

  .bg-about{
    display: none;
  }

  .about-content{
  	padding: 20px 0px 0px 10px;
  	h4{
  		padding:5px 0 0 45px;
  
  		&:before{
  			left: 0;
  		}
  	}
  
  }
  
  .counter{
    background: $primary-color;
  }
  
  .p-5{
    padding: 2rem !important;
  }
  h2, .h2 {
      font-size: 1.3rem;
      font-weight: 600;
      line-height: 36px;
  }

  .testimonial-item .testimonial-item-content {
      padding-left: 0px;
      padding-top: 30px;
  }

  .footer-socials {
    margin-top: 20px;
  }
  .footer-socials li a {
      margin-left: 0px;
  }
  .blog-item-meta span{
    display: block;
    margin: 6px 0px;
  }
  .widget {
      margin-bottom: 30px;
      padding-bottom: 0px; 
  }
  
  .dropdown-menu{
    display: none;
    width: 100%;
    text-align: center;
  }
}


@include tablet{
  .navbar-brand img {
    width:100px;
    top: -10px;
    -webkit-transition:all .3s ease-out 0s;
    -moz-transition:all .3s ease-out 0s;
    -ms-transition:all .3s ease-out 0s;
    -o-transition:all .3s ease-out 0s;
    transition:all .3s ease-out 0s   
  }
  .navbar-toggler{
    color: $light;
  }  
  .bg-about{
    display: none;
  }
  .counter{
    background: $primary-color;
  }
  
  .footer-socials {
    margin-top: 20px;
  }
  .footer-socials li a {
      margin-left: 0px;
  }
  .slider .block h1 {
      font-size: 56px;
      line-height: 70px;
  }
  .slider{
    padding: 165px 0 160px 0;
  }  
  .blog-item-meta span{
    display: block;
    margin: 6px 0px;
  }
  .widget {
      margin-bottom: 30px;
      padding-bottom: 0px; 
  }
  .dropdown-menu{
    display: none;
    width: 100%;
    text-align: center;
  }

}

@include desktop{
  .navbar-brand img {
    top: -15px;
    -webkit-transition:all .3s ease-out 0s;
    -moz-transition:all .3s ease-out 0s;
    -ms-transition:all .3s ease-out 0s;
    -o-transition:all .3s ease-out 0s;
    transition:all .3s ease-out 0s   
  }
   .bg-about{
    display: none;
  }
}


@include large-desktop{
 
}