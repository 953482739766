// About Setcion
.about{
	padding: 35px 0 20px 0;
}
.bg-about{
  	position: absolute;
  	content:"";
  	left: 0px;
  	top: 0px;
  	width: 45%;
  	min-height: 650px;
  	background: url("../images/about/home-8.jpg") no-repeat;
  	background-size: cover;
}


.about-content{
	padding: 20px 0px 0px 80px;
	h4{
		font-weight: 600;

		&:before{
			position: absolute;
			content:"\f14e";
			font-family: "Font Awesome 5 Free";
			font-size: 30px;
			position: absolute;
			top: 8px;
			left: -65px;
			font-weight: 700;
		}
	}

}
