/*=================================================================
  Latest Posts
==================================================================*/
.blog-item-content{
  h2{
	font-family:$extra-font;
  }
  h3{
    line-height: 36px;
  }

  h3 a{
    transition:all .4s ease 0s;
    &:hover{
      color: $primary-color!important;
    }
  }
}

.lh-36{
  line-height: 36px;
}


.tags{
  a{
    background: #f5f8f9;
    display: inline-block;
    padding: 8px 23px;
    border-radius: 38px;
    margin-bottom: 10px;
    border: 1px solid #eee;
    font-size: 14px;
    text-transform: capitalize;
  }
}



.pagination .nav-links a, .pagination .nav-links span.current {
    font-size: 20px;
    font-weight: 500;
    color: #c9c9c9;
    margin: 0 10px;
    text-transform: uppercase;
    letter-spacing: 1.2px;

}


.pagination .nav-links span.current, .pagination .nav-links a.next, .pagination .nav-links a.prev {
     color: $black;
}
h3.quote {
    font-size: 25px;
    line-height: 50px;
    font-weight: normal;
    padding: 0px 25px 0px 85px;
    margin: 65px 0 65px 0 !important;
    position: relative;
}

h3.quote::before {
    content: '';
    width: 55px;
    height: 2px;
    background: $primary-color;
    position: absolute;
    top: 25px;
    left: 0;
}

.nav-posts-title{
  line-height: 25px;
  font-size: 18px;
}



.latest-blog{
  position: relative;
  @extend .overly-2;
  padding-bottom: 150px;
}

.mt-70{
  margin-top: -70px;
}

.border-1{
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.blog-item{
  border-bottom: 1px solid rgba(0, 0, 0, 0.05)
}
