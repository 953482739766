/*=== MEDIA QUERY ===*/
@mixin large-desktop{
  @media screen and (min-width:993px){
    @content;
  }
}
@mixin desktop{
  @media screen and (min-width:769px) and (max-width:992px){
    @content;
  }
}
@mixin tablet{
  @media screen and (min-width:481px) and (max-width:768px){
    @content;
  }
}
@mixin mobile{
  @media screen and (min-width:401px) and (max-width:480px){
    @content;
  }
}
@mixin mobile-xs{
  @media screen and (max-width:400px){
    @content;
  }
}

