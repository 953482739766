#navbar{
  background:transparent;
  li.nav-item{
    margin-left: 15px;
  }

  .nav-link{
    font-family: $secondary-font;
    font-weight: 500;
    color: $light;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: .5px;
    -webkit-transition: all .25s ease;
       -moz-transition: all .25s ease;
        -ms-transition: all .25s ease;
         -o-transition: all .25s ease;
            transition: all .25s ease;
    
    &:hover, &:focus{
      color: $primary-color;
    }
  }


  .btn{
    padding: .5rem 1.5rem;
    color: $light;
  }

}

.navbar-brand{
  color: $light;
  position: relative;
  img{
	position: absolute;
	z-index: 5;
	width: 115px;
	top: -30px;
    -webkit-transition:all .3s ease-out 0s;
    -moz-transition:all .3s ease-out 0s;
    -ms-transition:all .3s ease-out 0s;
    -o-transition:all .3s ease-out 0s;
    transition:all .3s ease-out 0s 	
  }
}

.dropdown-menu{
  display:none;
  visibility: hidden;
  filter: alpha(opacity=0);
  opacity: 0;
   transition:all .2s ease-in, visibility 0s linear .2s, transform .2s linear;
   -webkit-transition: all .2s ease-in, visibility 0s linear .2s, -webkit-transform .2s linear;
   -o-transition: all .2s ease-in, visibility 0s linear .2s, -o-transform .2s linear;
   -ms-transition: all .2s ease-in, visibility 0s linear .2s, -ms-transform .2s linear;
  margin-top: 15px;
  padding: 0px;
  border-radius: 0px;
  display: inline-block;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown:hover .dropdown-menu{
   visibility: visible;
     -webkit-transition: all .45s ease 0s;
        -moz-transition: all .45s ease 0s;
         -ms-transition: all .45s ease 0s;
          -o-transition: all .45s ease 0s;
             transition: all .45s ease 0s;
   opacity: 1;
   display: block;
}
.dropdown-item{
  padding: .8rem 1.5rem;
  text-transform: uppercase;
  font-size: 14px;
}

.dropdown-item:hover{
  background: $primary-color;
  color: $light;
}

ul.dropdown-menu li {
    padding-left: 0px!important;
}


.navigation {
   position:absolute;
   top:0;
   left:0;
   width:100%;
   z-index:999;
//   -webkit-transition:all .3s ease-out 0s;
//   -moz-transition:all .3s ease-out 0s;
//   -ms-transition:all .3s ease-out 0s;
//   -o-transition:all .3s ease-out 0s;
//   transition:all .3s ease-out 0s
}

.sticky {
  position:fixed;
  z-index:99;
  background:lighten($secondary-color, 8%);
  -webkit-box-shadow:0 20px 50px 0 rgba(0,0,0,.05);
  -moz-box-shadow:0 20px 50px 0 rgba(0,0,0,.05);
  box-shadow:0 20px 50px 0 rgba(0,0,0,.05);
  -webkit-transition:all .3s ease-out 0s;
  -moz-transition:all .3s ease-out 0s;
  -ms-transition:all .3s ease-out 0s;
  -o-transition:all .3s ease-out 0s;
  transition:all .3s ease-out 0s
}

.sticky .navbar-brand img {
 width:90px;
 top: -20px;
  -webkit-transition:all .3s ease-out 0s;
  -moz-transition:all .3s ease-out 0s;
  -ms-transition:all .3s ease-out 0s;
  -o-transition:all .3s ease-out 0s;
  transition:all .3s ease-out 0s 
}

.navbar-toggler{
	color: $light;
}
#nav-header:not(.sticky) .navbar-toggler, #nav-header:not(.sticky) .navbar-collapse{
	display: none;
}

.navbar-nav .nav-item {
  position: relative;
}
.navbar-nav .nav-item a.nav-link::before {
 position:absolute;
 content:'';
 bottom:0;
 left:0;
 width:0;
 height:3px;
 background-color:#fff;
 -webkit-transition:all .3s ease-out 0s;
 -moz-transition:all .3s ease-out 0s;
 -ms-transition:all .3s ease-out 0s;
 -o-transition:all .3s ease-out 0s;
 transition:all .3s ease-out 0s
}

@media(max-width:991px) {
 .navbar-nav .nav-item a.nav-link::before {
  display:none
 }
}

.navbar-nav .nav-item a.nav-link.active::before,
.navbar-nav .nav-item a.nav-link:hover::before {
 width:100%
}

.sticky .navbar-nav .nav-item a.nav-link::before {
 background-color: $primary-color;
}
.sticky .navbar-nav .nav-item a.nav-link.active,
.sticky .navbar-nav .nav-item a.nav-link:hover {
 color: $primary-color;
}

.back-to-top {
 font-size:20px;
 color:#fff;
 position:fixed;
 right:20px;
 bottom:20px;
 width:40px;
 height:40px;
 line-height:40px;
 border-radius:5px;
 background-color:$primary-color;
 text-align:center;
 z-index:99;
 -webkit-transition:all .3s ease-out 0s;
 -moz-transition:all .3s ease-out 0s;
 -ms-transition:all .3s ease-out 0s;
 -o-transition:all .3s ease-out 0s;
 transition:all .3s ease-out 0s;
 -webkit-box-shadow:0 2px 5px rgba(0,0,0,.2);
 -moz-box-shadow:0 2px 5px rgba(0,0,0,.2);
 box-shadow:0 2px 5px rgba(0,0,0,.2);
 display:none
}
.back-to-top:hover {
 color:#fff;
 background-color:$secondary-color;
}